export class Accordion {
  constructor() {
    this.init()
  }

  init() {

    this.accordions = document.querySelectorAll('*[accordion]')
    this.accordions.forEach(a => {

      let trigger = a.querySelector('.accordion-trigger')
      let select = a.parentNode

      let height = a.scrollHeight
      let triggerHeight = trigger.offsetHeight + 50

      a.style.setProperty('--max-height', (height+triggerHeight) + 'px')
      a.style.setProperty('--init-height', triggerHeight + 'px')

      trigger.addEventListener('click', e => {
        if(a.classList.contains('open')) {
          a.classList.remove('open')
        } else {
          closeAccordion()
          a.classList.toggle('open')
        }
      })
    })

    const closeAccordion = () => {
      this.accordions.forEach(a => {
        a.classList.remove('open')
      })
    }

    document.addEventListener('click', event => {
      const isOutsideAccordion = Array.from(this.accordions).every(accordion => !accordion.contains(event.target));
      if (isOutsideAccordion) {
        closeAccordion();
      }
    });

  }

  refresh() {

    this.accordions = document.querySelectorAll('*[accordion]')
    this.accordions.forEach(a => {
      let height = a.scrollHeight
      a.style.setProperty('--max-height', height + 'px')
    })

  }


}
